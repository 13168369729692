import React from "react";

function ButtonLoader() {
  return (
    <div className="button-loader">
        <div className="button-loader-inner"></div>
    </div>
  );
}

export default ButtonLoader;
