import { useState, useEffect } from 'react'
import useSWR from 'swr'
import Api from '../../services/Api'
import { DATE_FORMAT_PRIMARY, formatDate } from '../../utils/date'
import { orders,getorderProductsUrl,getorderShippingUrl } from '../../ApiEndpoints'
import { getCustomerId } from '../../utils/auth'

function useGetOrderDetails(orderId) {
  const [customer, setCustomer] = useState()
  const id = getCustomerId()
  useEffect(() => {
    // setCustomer(JSON.parse(sessionStorage.getItem('customerId')))
    setCustomer(id);
  }, [])

  // Get order details.
  const getKey = () => {
    return orderId && customer
      ? `${orders}/${orderId}`
      : null
  }

  const getProductKey = () => {
    return getorderProductsUrl(orderId)
  }

  const getShippingAddressKey = () => {
    return getorderShippingUrl(orderId)
  }

  const { data, error } = useSWR(() => getKey(), getOrderDetails)

  // Get Products attached with same order.
  const { data: products } = useSWR(
    () => getProductKey(data?.products?.resource),
    getProductAttachedWithOrder
  )

  // Get Shipping Address attached with same order.
  const { data: shippingAddress } = useSWR(
    () => getShippingAddressKey(data?.shipping_addresses?.resource),
    getShippingAddress
  )

  const isLoading = !data && !error
  const orderInfo = data ? data : {}
  const orderProducts = products ? products : []
  const orderShippingAddress = shippingAddress ? shippingAddress : []

  return {
    isLoading,
    orderInfo,
    orderProducts,
    orderShippingAddress,
  }
}

export function DataToOrderInfo(data) {
  try {
    return {
      id: data.id || '',
      orderStatus: data.payment_method || '',
      dateCreated: formatDate(data?.date_created, DATE_FORMAT_PRIMARY) || '',
      subtotalExTax: data.subtotal_ex_tax || '',
      paymentMethod: data.payment_method || '',
      billing_address_first_name: data?.billing_address?.first_name || '',
      billing_address_last_name: data?.billing_address?.last_name || '',
      billing_address_company: data?.billing_address?.company || '',
      billing_address_street_1: data?.billing_address?.street_1 || '',
      billing_address_street_2: data?.billing_address?.street_2 || '',
      billing_address_state: data?.billing_address.state || '',
      billing_address_city: data?.billing_address.city || '',
      billing_address_country: data?.billing_address.country || '',
      billing_address_zip: data?.billing_address.zip || '',
      subTotalIncludingTax: data?.subtotal_inc_tax || '',
      totalIncludingTax: data?.total_inc_tax || '',
      shippingCostIncTax: data?.shipping_cost_inc_tax || '',
    }
  } catch (e) {
    return {}
  }
}

export function DataToOrderProducts(data) {
  try {
    return data.map((product) => {
      return {
        id: product?.id || '',
        quantity: product?.quantity || '',
        basePrice: product.base_price || '',
        productName: product.name || '',
      }
    })
  } catch (e) {
    return []
  }
}

export function DataToOrderShippingAddress(data) {
  try {
    return data.map((item) => {
      return {
        id: item?.id,
        shipping_address_first_name: item?.first_name || '',
        shipping_address_last_name: item?.last_name || '',
        shipping_address_company: item?.company || '',
        shipping_address_street_1: item?.street_1 || '',
        shipping_address_street_2: item?.street_2 || '',
        shipping_address_state: item?.state || '',
        shipping_address_city: item?.city || '',
        shipping_address_country: item?.country || '',
        shipping_address_zip: item?.zip || '',
      }
    })
  } catch (e) {
    return {}
  }
}

async function getOrderDetails(url) {
  const response = await Api.get(url)
  return response.data
}

async function getProductAttachedWithOrder(url) {
  const response = await Api.get(url)
  return response.data
}

async function getShippingAddress(url) {
  const response = await Api.get(url)
  return response.data
}

export default useGetOrderDetails
