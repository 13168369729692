import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import OrderProductDetails from "../components/account-orders/OrderProductDetails";

const OrderDetailsPage = () => {
  const location = useLocation();
  const user = true;
  const params = new URLSearchParams(location.search);
  const orderId = params.get("id");

  useEffect(() => {
    if (!user) {
      navigate("/login?redirect_to=/order-detail");
    }
  }, []);

  return <>{user ? <OrderProductDetails orderId={orderId} /> : <></>}</>;
};

export const Head = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get("id");
  const loginDetails = {
    title: `Midwest Goods Inc. - Order #${orderId}`,
  };
  return (
    <>
      <title>{loginDetails?.title}</title>
    </>
  );
};

OrderDetailsPage.Layout = Layout;
export default OrderDetailsPage;
